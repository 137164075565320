import { ArticleDetail, ArticleStatus, UpdateArticleDto, UserDetail } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import { ThreeDotHorizontalIcon, DeleteIcon, ArticleIcon } from "@/components/shared/icons"
import { cn } from "@/utils/cn"
import classNames from "classnames"
import React, { Fragment, useState } from "react"
import { useToast } from "@/hooks"
import FolderActionRow from "@/components/Workspace/FolderActions/FolderActionRow"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import client from "@/api/client"
import { Menu } from "@/components/MenuFloating"
import { User } from "firebase/auth"
import { googleAnalytics } from "@/lib/gtag"

interface ActionButtonsArticleProps {
  article: ArticleDetail
  menuClassName?: string
  user?: User | null
}

const ActionButtonsArticle = ({ article, menuClassName, user }: ActionButtonsArticleProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const toast = useToast()
  const qc = useQueryClient()

  const { mutate: mutateEdit, isPending: isLoadingEdit } = useMutation({
    mutationFn: (data: UpdateArticleDto) => client.api.articleControllerUpdateArticle(article!.id, data),
    onSuccess: () => {
      qc.invalidateQueries({ queryKey: ["infinite-explore-articles"] })
      qc.invalidateQueries({ queryKey: ["article", article?.id] })
    },
  })

  const { mutate: mutateDelete, isPending: isLoadingDelete } = useMutation({
    mutationFn: (articleId: string) => client.api.articleControllerDeleteArticle(articleId),
    onSuccess: () => {
      qc.invalidateQueries({ queryKey: ["infinite-explore-articles"] })
    },
    onError: () => {
      toast({ status: "error", title: "Failed to delete article" })
    },
  })

  const handleDelete = () => {
    mutateDelete(article.id)
    setIsOpen(false)

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Delete article",
        tab_name: "articles",
        article_id: article.id,
        article_title: article.title,
      },
    })
  }

  const handlePublish = () => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Publish article",
        tab_name: "articles",
        article_id: article.id,
        article_title: article.title,
      },
    })

    mutateEdit({
      status: ArticleStatus.PUBLISHED,
    })
    setIsOpen(false)
  }

  const actions = [
    {
      label: "Publish",
      icon: <ArticleIcon width={20} height={20} />,
      onClick: handlePublish,
      visible: true,
      enabled: true,
    },
    {
      label: "Delete",
      icon: <DeleteIcon className="text-red-500" width={20} height={20} />,
      onClick: handleDelete,
      visible: true,
      enabled: true,
    },
  ]

  return (
    <Fragment>
      <div
        className={cn(
          "transition-opacity duration-300 absolute top-1.5 right-1.5 opacity-100 lg:opacity-0 group-hover:opacity-100",
          {
            "opacity-100 lg:opacity-100": isOpen,
          },
          menuClassName,
        )}
      >
        <Menu
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          listClassName="p-0 bg-transparent"
          trigger={
            <IconButton
              onClick={e => {
                e.stopPropagation()
              }}
              colorScheme="secondary"
              className={classNames("bg-blackAlpha-700 px-2 min-h-[1.5rem]", {
                "bg-transparent hover:bg-transparent": menuClassName?.includes("bg-transparent"),
              })}
            >
              <ThreeDotHorizontalIcon className="w-3 h-3" />
            </IconButton>
          }
        >
          <div
            className={
              "text-sm w-[12.5rem] text-atherGray-300 bg-atherGray-900 border border-atherGray-850 p-2 rounded-2xl space-y-1"
            }
          >
            {actions
              .filter(a => a.visible)
              .map(action => (
                <FolderActionRow
                  key={action.label}
                  iconLeft={action.icon}
                  onClick={e => {
                    e.stopPropagation()
                    action.onClick()
                    setIsOpen(false)
                  }}
                  isDisabled={!action.enabled}
                >
                  {action.label}
                </FolderActionRow>
              ))}
          </div>
        </Menu>
      </div>
    </Fragment>
  )
}

export default ActionButtonsArticle
